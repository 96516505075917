// Define our labelmap
const labelMap = {
    1:{name:"Hey", color:'red'},
    2:{name:"Want", color:'yellow'},
    3:{name:"see", color:'green'},
    4:{name:"our", color:'black'},
    5:{name:"booth", color:'magenta'}
};

let det_counter = 0;
let last_det = "";
let last_insert = "";

// Define a drawing function
export const drawRect = (boxes, classes, scores, threshold, imgWidth, imgHeight, ctx)=>{
    if(boxes[0] && classes[0] && scores[0] > threshold) {
        try {
            // Extract variables
            const [y, x, height, width] = boxes[0];
            const text = classes[0];
            
            // Set styling
            ctx.strokeStyle = labelMap[text]["color"];
            ctx.lineWidth = 10;
            ctx.fillStyle = "white";
            ctx.font = "30px Arial";    
            
            // DRAW!!
            ctx.beginPath();
            ctx.fillText(labelMap[text]["name"] + " - " + Math.round(scores[0] * 100) / 100, x * imgWidth, y * imgHeight - 10);
            ctx.rect(x * imgWidth, y * imgHeight, width * imgWidth / 2, height * imgHeight / 1.5);
            ctx.stroke();

            if (last_det === "")
                last_det = labelMap[text]["name"];
            else if (last_det === labelMap[text]["name"])
                det_counter++;
            else {
                last_det = labelMap[text]["name"];
                det_counter = 0;
            }

            if (det_counter > 4) {
                if (last_insert === "Want")
                    document.getElementById("translation").innerHTML += " to ";
                document.getElementById("translation").innerHTML += labelMap[text]["name"] + " ";

                if (last_det === "Hey")
                    document.getElementById("translation").innerHTML = "Hey! "

                if (last_det === "booth")
                    document.getElementById("translation").innerHTML += "?";

                last_insert = last_det;
                det_counter = 0;
            }
        } catch (e) {
            console.error(e);
        }
    }
}