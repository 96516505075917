// Import dependencies
import React, { useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import * as tf from "@tensorflow/tfjs";
import Webcam from "react-webcam";
import "./App.css";
// 2. TODO - Import drawing utility here
// e.g. import { drawRect } from "./utilities";
import {drawRect} from "./utilities"; 

function App() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  // Main function
  const runCoco = async () => {
    // 3. TODO - Load network 
    // e.g. const net = await cocossd.load();
    // https://tensorflowjsrealtimemodel.s3.au-syd.cloud-object-storage.appdomain.cloud/model.json
    const net = await tf.loadGraphModel("https://demo.streamlined.tech/model/model.json");
    
    //  Loop and detect hands
    setInterval(() => {
      detect(net);
    }, 200);
  };

  const detect = async (net) => {
    // Check data is available
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // 4. TODO - Make Detections
      const img = tf.browser.fromPixels(video);
      const resized = tf.image.resizeBilinear(img, [640,480]);
      const casted = resized.cast('int32');
      const expanded = casted.expandDims(0);
      const obj = await net.executeAsync(expanded);

      const boxes = await obj[1].array();
      const classes = await obj[2].array();
      const scores = await obj[4].array();
      
      // Draw mesh
      const ctx = canvasRef.current.getContext("2d");

      // 5. TODO - Update drawing utility
      // drawSomething(obj, ctx)  
      requestAnimationFrame(()=>{drawRect(boxes[0], classes[0], scores[0], 0.8, videoWidth, videoHeight, ctx)}); 

      tf.dispose(img);
      tf.dispose(resized);
      tf.dispose(casted);
      tf.dispose(expanded);
      tf.dispose(obj);

    }
  };

  useEffect(()=>{runCoco()},[]);

  return (
    <div className="App">
      <header className="App-header">
        <Webcam
          ref={webcamRef}
          muted={true} 
          style={{
            textAlign: "center",
            zindex: 9,
            width: 640,
            height: 860,
          }}
        />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            textAlign: "center",
            zindex: 8,
            width: 640,
            height: 860,
          }}
        />

        <h3
          style={{
            paddingLeft: "50px"
          }}
        >Real Time Translation</h3>

        <h1
          id={"translation"}
          style={{
            marginBottom: "250px",
            paddingLeft: "50px",
            fontSize: "52px"
          }}
        > Hey! Want to see our booth?
        </h1>

        <Button
          variant={"contained"}
          color={"primary"}
          style={{
            width: "150px",
            height: "60px",
            backgroundColor: "#303540",
            borderStyle: "none",
            color: "white",
            marginLeft: "50px",
            marginBottom: "50px",
            fontSize: "20px"
          }}
          onClick = { () => { document.getElementById("translation").innerHTML = ""; } }
        > Clear Translation </Button>
      </header>
    </div>
  );
}

export default App;